@import 'styles/variables.less';

.br-packaging-orders__shipping-orders-container {
  padding: 24px;
  background-color: white;
  & .br-table__header__input-search {
    display: none;
  }
}

.br-table-component.br-shipping-orders__table {
  & .br-packaging-shipping-orders__status-column {
    width: fit-content;
    border-radius: 16px;
  }
  & .br-packaging-shipping-orders__tracking-number-text {
    color: @teal-500;
    cursor: pointer;
  }

  .br-amount-column__container {
    display: flex;
    gap: 12px;

    .br-amount-column__info-container {
      display: flex;
      flex-direction: column;
      white-space: nowrap;
      .br-amount-column__info-details {
        display: flex;
        align-items: center;

        .anticon.anticon-info-circle {
          > svg {
            path {
              fill: @gray-400;
            }
          }
        }
      }
    }
  }
}

.br-shipping-orders__table {
  .ant-pagination > li:not(.ant-pagination-prev, .ant-pagination-next) {
    display: none;
  }
}

.ant-tooltip.wallet-tooltip__overlay {
  .ant-tooltip-content {
    .ant-tooltip-arrow .ant-tooltip-arrow-content {
      --antd-arrow-background-color: white;
    }
    .ant-tooltip-inner {
      background: white;
      width: 100%;
      padding: 0;
      width: 374px;
      border-radius: 8px;

      .wallet-tooltip__container {
        display: flex;
        flex-direction: column;

        .wallet-tooltip__header {
          display: flex;
          gap: 8px;
          align-items: center;
          padding: 12px;
          box-shadow: 0px 1px 0px 0px @gray-200;
          &-title {
            .font({.body-medium()});
            color: @text-gray-dark;
          }
        }
        .wallet-tooltip__header {
          padding: 12px;
          color: @gray-500;
        }
      }
    }
  }
}

.br-packaging-orders__count {
  display: flex;
  align-items: center;
  gap: 4px;
}
